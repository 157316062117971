import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import cn from 'classnames'

import Page from '../components/Page'
import Container from '../components/elements/Container'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import Newsletter from '../components/Newsletter'

import styles from '../scss/pages/food-allergens.module.scss'

import DownloadSVG from '../assets/svg/download.svg'

const FoodAllergensPage = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query FoodAllergensPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/food-allergens" } }) {
        frontmatter {
          title
          description
          path
          pageIntro {
            ...PageIntroFragment
          }
          majorAllergens {
            title
            intro
            allergens {
              id
              name
              info
              image
              link
              blurbs {
                name
                text
              }
            }
          }
          downloads {
            allergenGuide {
              image {
                childImageSharp {
                  fixed(width: 400) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
            resources {
              download
              image {
                childImageSharp {
                  fixed(width: 400) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter
  const downloadData = pageData.downloads

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId)
    const bodyRect = document.body.getBoundingClientRect().top
    const elementRect = element.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect

    let offsetPosition = elementPosition - 50

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }

  const webPageJSONLD = {
    '@context': 'https://schema.org/',
    '@type': 'WebPage',
    name: pageData.title,
    speakable: {
      '@type': 'SpeakableSpecification',
      cssSelector: [
        'speakable-headline',
        'speakable-summary',
        'speakable-list',
        'speakable-item',
      ],
    },
    url: 'https://erudus.com/food-allergens',
  }

  return (
    <Page className="p-food-allergens has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
        webPage={webPageJSONLD}
      />

      <section className="c-section c-section--pageHeader c-section--pageHeader--background">
        <Container className="c-section__outer">
          <div className="c-section__inner">
            <div className="c-pageHeader">
              <div className="row">
                <div className="col-12 col-md-8 col-lg-9">
                  <div className="row">
                    <div className="col-12 col-xl-3">
                      <strong className="c-pageHeader__title">
                        {pageData.pageIntro.title}
                      </strong>
                    </div>
                    <div className="col-12 col-xl-9">
                      <h1
                        className="c-pageHeader__intro speakable-headline"
                        dangerouslySetInnerHTML={{
                          __html: pageData.pageIntro.text,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-none d-md-block col-12 col-md-4 col-lg-3">
                  <img
                    src="/images/icons/food-allergens/Major Food Allergens.svg"
                    alt="What are the 14 Major Food Allergens? Find out on Erudus.com"
                    className="img-fluid"
                    width="130"
                    height="123"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>

        <div className="c-wave c-wave--bottom">
          <svg viewBox="0 0 500 150" preserveAspectRatio="none">
            <path
              style={{ stroke: 'none' }}
              d="M-5.92,115.95 C125.56,-215.63 292.04,320.22 500.84,21.20 L500.00,150.00 L0.00,150.00 Z"
            ></path>
          </svg>
        </div>
      </section>

      <section id="allergen-list" className="c-section c-section--pageIntro">
        <div className="c-section__outer container">
          <div className="c-section__inner pt-5">
            <div className="c-pageIntro text-left">
              <div className="row">
                <div
                  className={cn(
                    'col-12 col-lg-8 speakable-summary',
                    styles.allergenLead
                  )}
                >
                  <p className={styles.allergenLeadP}>
                    The EU legislation, EU Food Information Regulation No.
                    1169/2011 (FIR) and Food Information for Consumers
                    Regulation (FIC) came into force on the 13th December 2014
                    impacting all Caterers, food Retailers, Wholesalers and
                    foodservice companies.
                  </p>
                  <p>
                    Caterers and food businesses are required by law to be able
                    to provide customers with accurate information on the EU’s
                    14 major food allergens if they are included in any of the
                    food products they produce, sell or serve.
                  </p>
                  <p className="d-lg-none">
                    You’ll find a list of the 14 major food allergens below.
                  </p>
                  <p className="d-none d-lg-block" aria-hidden={true}>
                    You’ll find a list of the 14 major food allergens to the
                    right.
                  </p>
                </div>
                <div className="col-12 col-lg-4">
                  <nav className={styles.allergenNav}>
                    <h2 className="pb-3">Allergen List</h2>
                    <ul className={cn('speakable-list', styles.allergenList)}>
                      {pageData.majorAllergens.allergens.map((mapValue) => (
                        <li
                          key={mapValue.id}
                          className={styles.allergenListItem}
                          onClick={(e) => scrollToElement(mapValue.id)}
                        >
                          {mapValue.name}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="benefits-to-your-customers"
        className={cn('c-section', styles.allergensSection)}
      >
        <Container>
          <div className={styles.allergensWrapper}>
            <div className="c-sectionTitle">
              <div className="row">
                <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                  <h3 className="c-sectionTitle__header mb-4">
                    {pageData.majorAllergens.title}
                  </h3>
                  <p className="c-sectionTitle__text">
                    {pageData.majorAllergens.intro}
                  </p>
                </div>
              </div>
            </div>
            {pageData.majorAllergens.allergens.map((allergen) => (
              <div
                key={allergen.id}
                id={allergen.id}
                className={styles.allergenItem}
              >
                <div className="row">
                  <div className="col-12 col-md-7 speakable-item">
                    <h2 className={styles.allergenItemHeader}>
                      {allergen.name}
                    </h2>
                    <p dangerouslySetInnerHTML={{ __html: allergen.info }} />
                  </div>
                  <div className="col-12 col-md-5 text-right">
                    <a
                      href="#allergen-list"
                      className="c-link d-inline-block pb-2"
                      style={{ fontSize: '0.75rem' }}
                    >
                      Back to Allergen List
                    </a>
                    <img
                      srcSet={`/images/allergens/${allergen.image}.png 1x, /images/allergens/${allergen.image}@2x.png 2x`}
                      src={`/images/allergens/${allergen.image}.png`}
                      className="img-fluid"
                      alt={`${allergen.name} is listed as one of the 14 major food allergens`}
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={cn('col-12', styles.allergenBlurbs)}>
                    {allergen.blurbs.map((blurb, i) => (
                      <div
                        key={`${blurb.name}_blurb_${i}`}
                        className={styles.allergenBlurb}
                      >
                        <h3 className={styles.allergenBlurbHeader}>
                          {blurb.name}
                        </h3>
                        <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                      </div>
                    ))}
                  </div>
                  <div className="col-12">
                    <p dangerouslySetInnerHTML={{ __html: allergen.link }} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>

      <section className="c-section">
        <Container>
          <div className={cn('row', styles.downloadsRow)}>
            <div className={cn('col-lg-7 d-flex', styles.downloadsInfo)}>
              <h2 className={styles.donwloadsH2}>Even more allergen support</h2>
              <h3 className={styles.donwloadsH3}>
                Our guide to the 14 Major Food Allergens
              </h3>
              <p className={styles.donwloadsP}>
                Don't forget to download our handy <strong>free guide</strong>{' '}
                to the EU's 14 Major Food Allergens for even more information,
                including 'What to look for on the label' plus 'Common foods
                containing'.
              </p>
              <a
                download
                href="https://d5wkd7ydh6co.cloudfront.net/food-allergens/erudus_allergen-guide.pdf"
                target="_blank"
                className={cn(
                  'c-button c-button--secondary mb-4',
                  styles.downloadsButton
                )}
              >
                <DownloadSVG className="mr-2" />
                Download Free Guide to the EU's 14 Major Food Allergens
              </a>
            </div>
            <div className="col-lg-5 text-center text-lg-right">
              <img
                srcSet={
                  downloadData.allergenGuide.image.childImageSharp.fixed.srcSet
                }
                src={downloadData.allergenGuide.image.childImageSharp.fixed.src}
                className={cn('img-fluid', styles.downloadsImage)}
                alt="Cover photo for the Erudus Allergens Guide"
                loading="lazy"
              />
            </div>
          </div>

          <div className={cn(styles.downloadsRow)}>
            <h3 className={styles.donwloadsH3}>
              More resources from the Food Standards Agency
            </h3>
            <p className={styles.donwloadsP}>
              Further details on allergens and allergen labelling are available
              in these resources
            </p>
            <div className="row pt-4">
              <div className="col-md-4 mb-md-5 px-5">
                <img
                  srcSet={
                    downloadData.resources[0].image.childImageSharp.fixed.srcSet
                  }
                  src={
                    downloadData.resources[0].image.childImageSharp.fixed.src
                  }
                  className={cn('img-fluid', styles.downloadsImage)}
                  alt={downloadData.resources[0].alt}
                  loading="lazy"
                />
                <a
                  download
                  href={downloadData.resources[0].download}
                  target="_blank"
                  className={cn(
                    'c-button c-button--secondary',
                    styles.downloadsButton
                  )}
                >
                  <DownloadSVG className="mr-2" /> Download
                </a>
              </div>
              <div className="col-md-4 mb-5 px-5">
                <a
                  href="https://www.food.gov.uk/business-guidance/allergen-guidance-for-food-businesses"
                  target="__blank"
                  rel="noopener noreferrer"
                >
                  <img
                    srcSet={
                      downloadData.resources[1].image.childImageSharp.fixed
                        .srcSet
                    }
                    src={
                      downloadData.resources[1].image.childImageSharp.fixed.src
                    }
                    className={cn('img-fluid')}
                    alt={downloadData.resources[1].alt}
                    loading="lazy"
                  />
                </a>
              </div>
              <div className="col-md-4 mb-5 px-5">
                <img
                  srcSet={
                    downloadData.resources[2].image.childImageSharp.fixed.srcSet
                  }
                  src={
                    downloadData.resources[2].image.childImageSharp.fixed.src
                  }
                  className={cn('img-fluid', styles.downloadsImage)}
                  alt={downloadData.resources[2].alt}
                  loading="lazy"
                />
                <a
                  download
                  href={downloadData.resources[2].download}
                  target="_blank"
                  className={cn(
                    'c-button c-button--secondary',
                    styles.downloadsButton
                  )}
                >
                  <DownloadSVG className="mr-2" /> Download
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <Newsletter />
    </Page>
  )
}

export default FoodAllergensPage
